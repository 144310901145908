<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      계정 삭제하기(회원탈퇴)<router-link to="/mypage/myInfo_setting"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_32">
      <h4>계정 삭제(회원탈퇴) 안내</h4>
      <div class="text_box">
        <p>계정을 삭제하기 전 아래 안내 사항을 꼭 확인해주세요.</p>
        <br />
        <p class="dot t_1">
          회원 탈퇴 시 사용하던 아이디는 재사용 및 복구가 불가능합니다.
        </p>
        <p class="dot t_1">
          회원 탈퇴 후 해당 계정을 통해 작성된 문서(리뷰)는 아래 ‘계정 삭제 후
          회원의 개인정보 처리방침’에 따라 모두 삭제되며 복구가 불가능합니다
        </p>
        <p class="dot t_1">
          리뷰어 채널이나 판매자 채널을 운영 중인 경우 계정 삭제하기가
          불가능합니다. 먼저 리뷰어 채널 및 판매자 채널을 삭제한 후 회원탈퇴
          과정을 진행해주세요
        </p>
        <p class="dot t_1">
          계정을 삭제하면 램 포인트는 모두 소멸됩니다. 램 포인트가 남아있는
          경우, 포인트를 모두 사용하신 후 계정을 삭제하시기 바랍니다.
        </p>
        <p class="dot t_1">
          계정 삭제 후 회원의 개인정보 처리방침: 회원의 개인정보는 회사의
          개인정보처리방침과 법령에 따라 해당 기간이 경과한 후 지체없이, 복구가
          불가능한 방식으로 파기됩니다.
        </p>
        <br />
        <p class="t_1">[회사의 개인정보처리방침]</p>
        <p class="num_1 t_2">
          부정거래 방지 및 금융사고 조사 등의 목적을 위해 회원 정보 : 6개월 보관
        </p>
        <p class="num_2 t_2">
          부정가입 등의 부정이용기록(가입인증 휴대 전화번호 또는 DI): 회원탈퇴
          후 6개월 보관
        </p>
        <p class="num_3 t_2">결제도용 등의 부정거래기록: 3년 보관</p>
        <br />
        <p class="t_1">[전자상거래 등에서의 소비자보호에 관한 법률]</p>
        <p class="num_1 t_2">계약 또는 청약 철회 등에 관한 기록: 5년 보관</p>
        <p class="num_2 t_2">
          대금결제 및 재화 등의 공급에 관한 기록: 5 년 보관
        </p>
        <p class="num_3 t_2">
          소비자의 불만 또는 분쟁 처리에 관한 기록: 3년 보관
        </p>
        <p class="num_4 t_2">표시·광고에 관한 기록: 6개월 보관</p>
        <br />
        <p class="t_1">[국세기본법]</p>
        <p class="num_1 t_2">
          각 세법이 규정하는 거래에 관한 장부 및 증빙서류 모두: 5년 보관
        </p>
        <br />
        <p class="t_1">[전자금융거래법]</p>
        <p class="num_1 t_2">전자금융에 관한 기록 : 5년 보관</p>
        <br />
        <p class="t_1">[전자문서 및 전자거래 기본법]</p>
        <p class="num_1 t_2">
          인증서와 인증 업무에 관한 기록: 인증서 효력 상실일로부터 10년 보관
        </p>
        <br />
        <p class="t_1">[통신비밀보호]</p>
        <p class="num_1 t_2">
          로그인 기록을 포함한 통신사실 확인자료: 3개월 보관
        </p>
      </div>
      <div class="check_box">
        <label
          ><input
            type="checkbox"
            name=""
            id="is_check"
            v-model="isCheck"
          />본인은 안내 사항을 모두 확인했으며 이에 동의합니다.</label
        >
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_btn yellow">
      <div>
        <router-link to="" @click.native="withdrawal">확인</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isCheck: false,
    };
  },
  created() {
    //인증체크
    if (!this.checkAuth) {
      this.$toast.default("비밀번호 인증이 필요합니다.");
      this.$router.push("/mypage/auth_check");
    }
  },
  computed: {
    ...mapState("member", ["result", "msg"]),
    ...mapState("mypage", {
      myResult: "result",
      myMsg: "msg",
      myInfo: "myInfo",
      myChannel: "channel",
      checkAuth: "checkAuth",
    }),
  },
  methods: {
    withdrawal() {
      if (!this.isCheck) {
        this.$toast.default("회원 삭제에 동의해주세요.");
        document.querySelector("#is_check").focus();
        return false;
      }
      this.$confirm("회원 삭제(회원 탈퇴)를 계속하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("member/withdrawal");
          this.$toast.default(this.msg);
          if (this.result) {
            this.$store.dispatch("member/logOut");
            // this.$toast.default("로그아웃 되었습니다.");
            if (this.$route.path !== "/") this.$router.push("/");
          }
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style></style>
